import './App.css';
import { SettingsPane } from './Components/SettingsPane';
import { ApartmentList } from './Components/ApartmentList';

function App() {
  return (
    <div className="App">
      <ApartmentList />
			<SettingsPane />
    </div>
  );
}

export default App;
