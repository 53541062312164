import React, { useState, useEffect } from 'react';

export const SettingsPane = (props) => {
	const [lastUpdate, setLastUpdate] = useState(null);
	const [isScraping, setIsScraping] = useState(false);

	useEffect(() => {
		let url = ""
		if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			url = "https://localhost:7098/api/lastscrape";
		} else {
			url = "/api/lastscrape";
		}

		fetch(url)
			.then(res => res.json())
			.then(data => setLastUpdate(data));
	},[]);

	const forceScrape = () => {
		setIsScraping(true);
		let url = ""
		if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			url = "https://localhost:7098/api/forcescrape";
		} else {
			url = "/api/forcescrape";
		}

		fetch(url)
			.then(res => window.location.reload())
	}

	return (
		<div className="card my-4">
			<div className="card-footer d-flex justify-content-between align-items-center">
				{lastUpdate !== null && (
					<span>Scraped {lastUpdate.newApartments} new apartment(s) on {new Date(Date.parse(lastUpdate.scrapeDate)).toUTCString()}</span>
				)}
				<button 
					className="btn btn-primary btn-sm" 
					disabled={isScraping}
					onClick={() => forceScrape()}
				>
					{isScraping ? (
						  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
					) : (<span>Force Scrape</span>)}
				</button>
			</div>
		</div>
	)
}