import React, { useState, useEffect } from 'react';

export const ApartmentList = (props) => {
	const [apartments, setApartments] = useState([]);

	useEffect(() => {
		let url = ""
		if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			url = "https://localhost:7098/api/apartments";
		} else {
			url = "/api/apartments";
		}

		fetch(url)
			.then(res => res.json())
			.then(data => setApartments(data));

	}, []);
	if (apartments.length === 0) return null;

	return (
		<div className="row row-cols-1 row-cols-md-4 g-4">
			{apartments.map(i => (
				<div className="col">
					<ApartmentCard apartment={i} key={i.url} />
				</div>
			))}
		</div>
	)
}

const ApartmentCard = (props) => {
	const { apartment } = props;

	return (
		<div className="card h-100">
			<img src={`data:image/jpeg;base64,${apartment.image}`} className="card-img-top" />
			<div className="card-body">
				<h5 className="card-title m-0">
					{apartment.address}
				</h5>
				<span className="mb-2">{apartment.area}</span>
				<ul className="list-unstyled">
					<li>Size: {apartment.rooms} {apartment.rooms > 1 ? ('rooms') : ('room')}, {apartment.size}m<sup>2</sup></li>
					<li>Floor: {apartment.floor}</li>
					<li>Price: {apartment.price}</li>
				</ul>
				{apartment.attributes.map(attr => (
					<span className="badge bg-primary">{attr}</span>
				))}
			</div>
			<div className="card-footer d-flex">
				<a href={apartment.url}
					className="btn btn-primary btn-sm"
					target="_blank"
				>
					View
				</a>
			</div>
		</div>
	)
}